<template>
  <div class="homeBillList">
    <Back :title="popoutObj[params.popoutId]"></Back>
    <main>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list-item" v-for="i in list">
          <h1 class="van-hairline--bottom"><i class="text-icon"></i><span class="font-14">{{ i.tenantName }}</span></h1>
          <div class="text-content font-14">
            <p class="text-p">
              <span class="grey">房源</span>
              <span>{{ i.projectName }}-{{ i.unitName }}-{{ i.resourceNames }}</span>
            </p>
            <p class="text-p">
              <span class="grey">客户名称</span>
              <span>{{ i.tenantName }}</span>
            </p>
            <template v-if="Number(params.popoutId) <= 5">
              <p class="text-p">
                <span class="grey">费用类型</span>
                <span>{{ i.exacctName }}</span>
              </p>
              <p class="text-p">
                <span class="grey">费用周期开始时间</span>
                <span>{{ i.cycleStart }}</span>
              </p>
              <p class="text-p">
                <span class="grey">费用周期结束时间</span>
                <span>{{ i.cycleEnd }}</span>
              </p>
              <p class="text-p">
                <span class="grey">账单日期</span>
                <span>{{ i.billDate }}</span>
              </p>
              <p class="text-p">
                <span class="grey">账单金额</span>
                <span>{{ i.billAmount }}</span>
              </p>
              <p class="text-p">
                <span class="grey">已收金额</span>
                <span>{{ i.paidAmount }}</span>
              </p>
              <p class="text-p">
                <span class="grey">账单状态</span>
                <span>{{ i.billStatusName }}</span>
              </p>
            </template>
            <template v-else>
              <p class="text-p">
                <span class="grey">租赁面积</span>
                <span>{{ i.quantity }}</span>
              </p>
              <p class="text-p">
                <span class="grey">合同开始</span>
                <span>{{ i.effDate }}</span>
              </p>
              <p class="text-p">
                <span class="grey">合同结束</span>
                <span>{{ i.expDate }}</span>
              </p>
              <p class="text-p">
                <span class="grey">合同签约时间</span>
                <span>{{ i.signDate }}</span>
              </p>
            </template>
          </div>
        </div>
      </van-list>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getContractHomeList } from "@/api/contract/contract";
import { getLastDay } from '@/utils/utils';
export default {
  components: { Back },
  data() {
    return {
      active: 0,
      params: {
        page: 1,
        limit: 10,
        popoutId: this.$route.query.popoutId,
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        endDate: new Date(getLastDay()).getTime(),
      },
      list: [],
      loading: false,
      finished: false, //是否结束加载，切换状态后记得开启
      popoutObj: {
        1: "应收合同数",
        3: "未收合同数",
        4: "申请延期合同数",
        5: "逾期合同数",
      }
    };
  },
  computed: {
    title() {
      return this.params.popoutId == 6 ? "新签合同数" : "续签合同数"
    }
  },
  mounted() {
    this.getContractHomeList();
  },
  methods: {
    // 【请求】获取房源数量
    getContractHomeList() {
      let data = this.params;
      getContractHomeList(data).then((res) => {
        if (res.success) {
          if (res.data) {
            this.list = [...this.list, ...res.data];
            this.loading = false;
            if (this.list.length >= res.count) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    // 【请求】下拉加载
    onLoad() {
      this.params.page++;
      this.getContractHomeList();
    },
    // 状态变更重置
    reset() {
      this.list = [];
      this.params.page = 1;
      this.finished = false;
      this.getContractHomeList();
    },

  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.homeBillList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  background: #F2F1F6;

}

.list-item {
  background: #fff;
  padding: .1rem;
  margin: .1rem;
  border-radius: .06rem;

  p {
    padding: .08rem 0;
    display: flex;
    align-items: center;
  }

  h1 {
    padding: .1rem;
    display: flex;
    align-items: center;
  }

  .text-icon {
    display: inline-block;
    background: #1989fa;
    width: 0.04rem;
    border-radius: 0 0.04rem 0.04rem 0;
    margin-right: 0.1rem;
    height: 0.16rem;
  }

  .text-content {
    padding: .08rem;
  }
}

.blue {
  color: #4F94F3;
  font-size: .18rem;
  font-weight: bold;
  margin-right: .03rem;
}

.area {
  display: flex;
  justify-content: space-between;
}

.text-p {
  display: flex;
  justify-content: space-between;
}

.grey {
  color: #666666;
}

.black {
  color: #333333;
}

.green {
  color: #57B988;
}

.font-12 {
  font-size: .12rem;
}

.font-14 {
  font-size: .14rem;
}
</style>

<style  lang="scss">
.homeBillList {
  .van-tab {
    background: #F2F1F6 !important;
  }
}
</style>